import Pusher from 'pusher-js'

import authConfig from '@/config/auth'
import {
  CLINICIAN_BREAK_SET,
  CLINICIAN_SET_PAUSED_UNTIL,
} from '@/store/mutations/clinician/constants'
import { assignmentHelpers } from '@/store/assignments'
import { notificationHelpers } from '@/store/notifications'
import vueEnv from '@/utils/env'
import { debounce } from 'lodash'
import { typeformHelpers } from '@/store/typeform'
import { TYPEFORM_TYPES } from '@/store/typeform/constants'
import { EVENT_TYPES } from '@/constants/pusher-event-types'

const assignmentActions = assignmentHelpers.actionKeys
const assignmentGetters = assignmentHelpers.getterKeys
const notificationActions = notificationHelpers.actionKeys
const typeformActions = typeformHelpers.actionKeys

const Websocket = {
  install(Vue) {
    const proto = Vue.prototype

    function dispatch(action, data) {
      proto.$store.dispatch(action, data)
    }

    function modifyAssignments(event, action, data) {
      const assignment = proto.$store.getters[assignmentGetters.current].find(
        (asn) => asn.id === data.id
      )

      if (assignment) {
        const message = `${assignment.consult?.consult_rate?.display_name} was ${action}`
        proto.$snackbar.show(message)
      }

      dispatch(event, data)
    }

    Vue.prototype.$websocket = new Vue({
      data() {
        return {
          debouncedRefresh: null,
        }
      },
      created() {
        this.debouncedRefresh = debounce(this.refreshReadyForResponse, 5000, {
          leading: true,
        })
      },
      methods: {
        async subscribe() {
          const useAuth0 = Vue.prototype.$auth0.isAuth0Enabled
          const token = useAuth0
            ? await Vue.prototype.$auth0.getTokenSilently()
            : Vue.prototype.$auth.getAccessToken()
          const { uuid: clinicianUUID } = proto.$store.getters.clinician
          if (clinicianUUID === undefined) {
            throw new Error('No Clinician UUID')
          }
          const pusher = new Pusher(vueEnv.pusherAppKey, {
            cluster: 'us2',
            forceTLS: true,
            authEndpoint: `${vueEnv.onDemandApi}/internal/pusher/auth`,
            auth: {
              headers: {
                Authorization: 'Bearer ' + token,
                'Okta-Client-Id': authConfig.oidc.clientId,
              },
            },
          })

          const channel = pusher.subscribe(`private-clinician-${clinicianUUID}`)

          channel.bind(EVENT_TYPES.NEW_ASSIGNMENT, (data) => {
            dispatch(assignmentActions.addToCurrent, data)
          })

          channel.bind('new-consult-booking', (data) => {
            if (
              new Date(data.appointment.buffer_start_at) <
              new Date(new Date().getTime() + 24 * 3600 * 1000)
            ) {
              dispatch(assignmentActions.addToFuture, data)
            }
          })

          channel.bind(EVENT_TYPES.CONSULT_BOOKING_REMINDER, () => {
            dispatch(notificationActions.addToBrowserNotifications, {
              type: EVENT_TYPES.CONSULT_BOOKING_REMINDER,
            })
          })

          channel.bind('canceled-consult-booking', (data) =>
            dispatch(assignmentActions.removeFromFuture, data)
          )

          channel.bind('assignment-accepted', (data) =>
            dispatch(assignmentActions.accept, data)
          )

          channel.bind('show-consult-stuck-options', (flaggedAssignmentId) => {
            const flaggedAssignment = proto.$store.getters[
              assignmentGetters.current
            ].find((assignment) => assignment.id === flaggedAssignmentId)

            if (flaggedAssignment) {
              dispatch(assignmentActions.flag, {
                assignment: flaggedAssignment,
                alert: true,
              })
            }
          })

          channel.bind('assignment-completed', (data) => {
            modifyAssignments(
              assignmentActions.removeFromCurrent,
              'completed',
              data
            )
            // this.debouncedRefresh()
          })
          channel.bind('assignment-declined', (data) => {
            modifyAssignments(
              assignmentActions.removeFromCurrent,
              'declined',
              data
            )
            // this.debouncedRefresh()
          })
          channel.bind('assignment-voided', (data) => {
            modifyAssignments(
              assignmentActions.removeFromCurrent,
              'voided',
              data
            )
            // this.debouncedRefresh()
          })
          channel.bind('assignment-held', (data) => {
            modifyAssignments(assignmentActions.removeFromCurrent, 'held', data)
            // this.debouncedRefresh()
          })
          channel.bind('assignment-deferred', (data) => {
            modifyAssignments(
              assignmentActions.removeFromCurrent,
              'deferred',
              data
            )
            // this.debouncedRefresh()
          })
          channel.bind('assignment-reassigned', (data) => {
            modifyAssignments(
              assignmentActions.removeFromCurrent,
              'reassigned',
              data
            )
            // this.debouncedRefresh()
          })

          channel.bind('assignment-auto-reassigned', () => {
            dispatch(notificationActions.loadNotifications)
            // this.debouncedRefresh()
          })

          channel.bind('new_chat_response', () => {
            this.debouncedRefresh()
          })

          channel.bind(EVENT_TYPES.IDENTIFICATION_RESUBMITTED, (data) => {
            this.debouncedRefresh()
            dispatch(notificationActions.addToBrowserNotifications, {
              type: EVENT_TYPES.IDENTIFICATION_RESUBMITTED,
              ...data,
            })
          })

          channel.bind(EVENT_TYPES.STARTED_NOT_COMPLETE_WARNING, (data) => {
            dispatch(notificationActions.addToBrowserNotifications, {
              type: EVENT_TYPES.STARTED_NOT_COMPLETE_WARNING,
              ...data,
            })
          })

          channel.bind('break-over', () => {
            proto.$store.commit(CLINICIAN_BREAK_SET, null)
          })

          channel.bind('high-earnings-shift-ending', (data) => {
            dispatch(typeformActions.enqueueForm, {
              formId: TYPEFORM_TYPES.HIGH_EARNINGS_SHIFT_ENDED,
              data: data,
            })
          })

          channel.bind('clinician-activity', (data) => {
            // TODO: Create consult-ignored notification in on-demand API to be able to remove this
            dispatch(assignmentActions.loadCurrent)
            this.debouncedRefresh()

            proto.$store.commit(
              CLINICIAN_SET_PAUSED_UNTIL,
              data.paused_for_seconds
            )
          })

          channel.bind('consult-event', (data) => {
            const assignments = proto.$store.getters[assignmentGetters.current]
            const upcoming = proto.$store.getters[assignmentGetters.future]

            const assignment =
              assignments.find((asn) => asn.consult.id === data.id) ||
              upcoming.find((asn) => asn.consult.id === data.id)

            dispatch(assignmentActions.loadStatusCount, 'held')
            dispatch(assignmentActions.loadStatusCount, 'deferred')

            // It's possible to not have a booking or matching consult
            if (!assignment) {
              return
            }

            const {
              consult: {
                consult_rate: { display_name: consultRateName },
              },
            } = assignment
            const message = `${consultRateName} was ${data.event}`

            switch (data.event) {
              case 'deferred':
                dispatch(assignmentActions.removeFromCurrent, data)
                Vue.prototype.$snackbar.show(message)
                break
              case 'held':
                dispatch(assignmentActions.removeFromCurrent, data)
                Vue.prototype.$snackbar.show(message)
                break
              case 'canceled':
                // only hit the assignments upcoming remove because data.id is a consult id,
                // and assignments will be removed via assignment-voided
                dispatch(assignmentActions.removeFromFuture, data)
                Vue.prototype.$snackbar.show(message)
                break
              default:
                return
            }
          })
        },
        refreshReadyForResponse() {
          dispatch(assignmentActions.loadReadyForResponse)
          dispatch(assignmentActions.loadStatusCount, 'deferred')
        },
      },
    })
  },
}

export default Websocket
